
import { Options, Vue } from 'vue-class-component'
import courses from '@/components/Memberportal/Courses/index.vue'

@Options({
  components: {
    courses
  }
})
export default class Courses extends Vue {}
